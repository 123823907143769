import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-green/theme.css'

router.beforeEach((to, from, next) => {
    document.title = 'รัฐบาลออกมาตรการลดหย่อนภาษีครั้งใหม่ แทน  ช้อปดีมีคืน 2567\n' +
        'โครงการ Easy E-Receipt ซื้อสินค้าและบริการจากร้านค้าที่อยู่ในระบบภาษี \n' +
        'โดยจะต้องออกใบกำกับภาษีเต็มรูปแบบ ในรูปแบบอิเล็กทรอนิกส์\n' +
        'ผ่านระบบ e-Tax Invoice & e-Receipt ของกรมสรรพากร \n' +
        'มูลค่าสูงสุดถึง 50,000 บาท สามารถนำ e-Tax หรือ e-Receipt มาลดหย่อน\n' +
        'โดยเป็นสินค้าและบริการที่ซื้อในช่วงวันที่ 1 มกราคม – 15 กุมภาพันธ์ 2567';
    next();
});
createApp(App).use(router).use(PrimeVue).mount('#app');


