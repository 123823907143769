<script setup>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {onMounted, ref} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import dataJson from './data.json';
import {debounce} from 'lodash';

const searchText = ref('');
const dataSearch = ref();
const dataCount = ref(0);

const handleInputChange = () => {
  handleInputDebounce()
}

const handleInputDebounce = debounce(function () {
  const searchTerms = searchText.value.toLowerCase().toLowerCase().split(' '); // Split query into individual words
  dataSearch.value = dataJson.filter((item) =>
      searchTerms.every((term) =>  item.name.toLowerCase().includes(term))
  );
}, 600)
const loadData = () => {
  // pare json to object
  dataSearch.value = dataJson
  dataCount.value = dataJson.length
}

// const getdata = () => {
//   const req = {
//     "name": "xxxx",
//     "pageNum": 1,
//     "pageSize": 1000000
//   }
//   axios.post('https://etax.rd.go.th/rd/services/rs.registeredlist/searchNew', req)
//       .then(function (response) {
//         // handle success
//         console.log(response);
//       })
//       .catch(function (error) {
//         // handle error
//         console.log(error);
//       })
//       .then(function () {
//         // always executed
//       });
//
// }

onMounted(() => {
  loadData();
})

</script>

<template>
  <div class="ml-4 mt-4">
    <span class="p-input-icon-left mr-2">
      <i class="pi pi-search"/>
      <InputText v-model="searchText" placeholder="Search" @input="handleInputChange"/>
    </span>
    <Button class="ml-2 mb-4" label="Submit" @click="handleInputChange"/>
  </div>
  <p>Record {{dataCount}} Update Date: 1/1/2567</p>
  <DataTable :value="dataSearch" paginator :rows="50" :rowsPerPageOptions="[50, 100, 150, 200]" stripedRows tableStyle="min-width: 50rem">
    <Column field="tax" header="Tax"></Column>
    <Column field="name" header="Name"></Column>
    <Column field="isvat" header="Is Vat"></Column>
  </DataTable>

</template>

<style scoped>


</style>